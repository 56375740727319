import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  CardContainer,
  ChatImage,
  Container,
  ImageWrapper,
  InfoContainer,
  PeopleImage,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './HowSessionIsGoing.styles'
import { Content } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'

export const HowSessionIsGoing = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2 style={{ textAlign: 'center' }}>
                Как проходит онлайн-сессия
                <br />в чате с психологами YouTalk?
              </Title.H2>
            </TitleWrapper>
            <CardContainer>
              <Container>
                <ImageWrapper>
                  <PeopleImage />
                </ImageWrapper>
                <InfoContainer>
                  <Title.H3>Синхронная переписка</Title.H3>
                  <TextWrapper>
                    <Text.Large semiBold>
                      Консультация 50 минут в онлайн-режиме. Проходит так же,
                      как обычная консультация, но вместо общения по видеосвязи
                      и голосом, вы с психологом обмениваетесь текстовыми
                      сообщениями.
                    </Text.Large>
                    <Text.Large semiBold>
                      Этот формат хорошо подходит людям, которые не могут
                      уединиться, чтобы созвониться со специалистом по видео.
                    </Text.Large>
                  </TextWrapper>
                </InfoContainer>
              </Container>
              <Container>
                <ImageWrapper>
                  <ChatImage />
                </ImageWrapper>
              </Container>
            </CardContainer>

            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={() => GAEvent.openNavigate('panicAttackLanding')}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
