import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { AdantagesEmdr } from '../organisms/Emdr/AdantagesEmdr'
import { EmdrHero } from '../organisms/Emdr/EmdrHero'
import { FindYourEmdr } from '../organisms/Emdr/FindYourEmdr'
import { HowEmdrIsGoing } from '../organisms/Emdr/HowEmdrIsGoing'
import { HowItSection } from '../organisms/HowItSection'
import { HowToFindEmdr } from '../organisms/Emdr/HowToFindEmdr'
import { InfoChatCard } from '../organisms/Emdr/InfoChatCard'
import { ProductMicromarkupComponent } from '../atoms/SEO/Micromarkup/Product'
import { PsychologistsWithEMDR } from '../organisms/Emdr/PsychologistsWithEMDR'
import { QueriesEmdr } from '../organisms/Emdr/QueriesEmdr'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { StepsToFind } from '../organisms/Emdr/StepsToFind'
import { TariffBlock } from '../organisms/SeoTariffsAndBundles/TariffBlock'
import { TarrifPackages } from '../organisms/SeoTariffsAndBundles/TarrifPackages'
import { WhatIsEmdr } from '../organisms/Emdr/WhatIsEmdr'
import { WhenNeedEmdr } from '../organisms/Emdr/WhenNeedEmdr'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: var(--height);
  background: #dfebf9;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const Emdr = ({ data }) => (
  <App>
    <SEO
      description="Онлайн консультации EMDR (ДПДГ) психотерапевта ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="EMDR терапия онлайн — консультации психолога специалиста по ДПДГ | YouTalk"
    >
      <ProductMicromarkupComponent name="Записаться к EMDR онлайн терапевту" />
    </SEO>
    <Page>
      <Header />
      <Main>
        <EmdrHero />
        <WhatIsEmdr />
        <WhenNeedEmdr />
        <QueriesEmdr />
        <HowEmdrIsGoing />
        <StepsToFind />
        <HowToFindEmdr />
        <PsychologistsWithEMDR data={pick(['psychologists'], data)} />
        <TariffBlock />
        <TarrifPackages />
        <AdantagesEmdr />
        <FindYourEmdr />
        <InfoChatCard />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default Emdr

export const query = graphql`
  query Emdr {
    psychologists: allPsychologistsData(
      filter: {
        nearest_slot: { ne: null }
        visibility: { eq: "OPEN" }
        approaches: { elemMatch: { name: { in: "ДПДГ (EMDR)" } } }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
